<template>
  <v-text-field
    ref="input"
    outlined
    v-model="value"
    :rules="validators"
    validate-on-blur
    :placeholder="field.placeholder || '-'"
    :type="isNumberType ? 'number' : 'text'"
    v-mask="fieldMask"
    :clearable="field.clearable"
    @click:clear="clearValue"
    @input="setValue"
  >
    <template #label>
      {{ field.text }} <span v-html="field.unit" />
    </template>
    <template
      v-if="field.icon"
      #prepend-inner
    >
      <Icon
        :name="field.icon"
        class="mr-2"
      />
    </template>
  </v-text-field>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import { parseAsBasicUnit, getFieldMask } from '../../../utils'
import { priceValues } from '../../../utils/typesEnum'
import get from 'lodash/get'

export default {
  mixins: [inputMixin],
  data: () => ({
    value: null
  }),
  computed: {
    fieldMask () {
      const { rules } = this.field
      if (rules?.includes('phoneNumber')) return getFieldMask('phoneNumber')
      if (rules?.includes('postCode')) return getFieldMask('postCode')
      if (rules?.includes('bankAccount')) return getFieldMask('bankAccount')
      return undefined
    },
    isPriceValue () {
      return priceValues.includes(this.field.name)
    },
    isNumberType () {
      const { name } = this.field
      return priceValues.includes(name) || name === 'volume' || name === 'maxWeight'
    }
  },
  mounted () {
    this.value = this.getValue()
    this.$nextTick().then(() => {
      this.$refs.input.focus()
    })
  },
  methods: {
    getValue () {
      let value = get(this.data, this.field.value, '')
      if (this.isPriceValue) value /= 100
      if (this.field.name === 'volume' || this.field.name === 'maxWeight') value /= 1000
      return value
    },
    setValue (value) {
      const { name } = this.field
      if (this.isPriceValue) value = parseAsBasicUnit(value)
      if (name === 'volume' || name === 'maxWeight') value = parseAsBasicUnit(value, 1000)
      this.$emit('update', { [name]: value })
    },
    clearValue () {
      this.$nextTick(() => { this.setValue(null) })
    },
    getFieldMask,
  }
}
</script>
